/* src/App.css */

body {
  font-family: "Roboto", sans-serif;
}

.animated-border {
  position: relative;
  padding: 6px;
  border-radius: 8px;
  overflow: hidden;
  background: linear-gradient(45deg, #ff6ec4, #7873f5, #ff6ec4, #7873f5);
  background-size: 400%;
  animation: borderAnimation 5s linear infinite;
}

.animated-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: inherit;
  z-index: -1;
  filter: blur(5px);
}

@keyframes borderAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header-title {
  color: #1a73e8;
}

.intro-text {
  color: #fbbc05;
}

.button-text {
  color: #333333;
  font-family: "Playfair Display", serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #333333;
  border-radius: 4px;
  padding: 8px 16px;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.button-text:hover {
  background-color: #e9ecef;
  border-color: #666666;
}

.contact-title {
  color: #1a73e8;
}

.contact-link {
  color: #34a853;
}
